import React from "react";

import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import AppContext from "../context/AppContext";

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      margin: "8px 0",
    },
  },
}));

const VisitForm = (props) => {
  const app = React.useContext(AppContext);

  const classes = useStyles();

  const [isValid, setIsValid] = React.useState(false);

  const handleChange = (event) => {
    setIsValid(event.target.form.checkValidity());
  };

  return (
    <form
      className={classes.root}
      onSubmit={props.handleSubmit}
      noValidate
      autoComplete="off"
      onChange={handleChange}
    >
      <TextField
        id="fullNameInput"
        name="fullName"
        label="Nome completo"
        required
        fullWidth
      />

      <TextField
        id="phoneInput"
        name="phone"
        label="Celular"
        required
        fullWidth
      />

      <TextField
        id="emailInput"
        name="email"
        label="E-mail"
        type="email"
        required
        fullWidth
      />

      <TextField
        id="realStateAgencySelect"
        name="realStateAgency"
        label="Imobiliária"
        defaultValue=""
        required
        fullWidth
        select
      >
        {app.listFields
          ? Object.entries(app.listFields.PROPERTY_112.DISPLAY_VALUES_FORM).map(
              (options) => (
                <MenuItem key={options[0]} value={options[0]}>
                  {options[1]}
                </MenuItem>
              )
            )
          : []}
      </TextField>

      <TextField
        id="salesPointSelect"
        name="salesPoint"
        label="Plantão de venda"
        defaultValue=""
        required
        fullWidth
        select
      >
        {app.listFields
          ? Object.entries(app.listFields.PROPERTY_110.DISPLAY_VALUES_FORM).map(
              (options) => (
                <MenuItem key={options[0]} value={options[0]}>
                  {options[1]}
                </MenuItem>
              )
            )
          : []}
      </TextField>

      <TextField
        id="ventureSelect"
        name="venture"
        label="Empreendimento de interesse"
        defaultValue=""
        required
        fullWidth
        select
      >
        {app.leadFields
          ? app.leadFields.UF_CRM_1598362761.items.map((option) => (
              <MenuItem key={option.ID} value={option.ID}>
                {option.VALUE}
              </MenuItem>
            ))
          : []}
      </TextField>

      <TextField
        id="mediumSelect"
        name="medium"
        label="Mídia"
        defaultValue=""
        required
        fullWidth
        select
      >
        {app.listFields
          ? Object.entries(app.listFields.PROPERTY_116.DISPLAY_VALUES_FORM).map(
              (options) => (
                <MenuItem key={options[0]} value={options[0]}>
                  {options[1]}
                </MenuItem>
              )
            )
          : []}
      </TextField>

      <TextField
        id="realStateAgentInput"
        name="realStateAgent"
        label="Corretor"
        required
        fullWidth
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!isValid}
      >
        Enviar
      </Button>
    </form>
  );
};

export default VisitForm;
