import React from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const Panel = (props) => {
  return (
    <Box p={3}>
      <Container maxWidth="sm">
        <Paper>
          <Box p={3}>{props.children}</Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Panel;
