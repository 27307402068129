import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    color: "white",
    textDecoration: "none",
  },
}));

const NavAppBar = () => {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar>
        <Link href="/">
          <Typography className={classes.root} variant="h4">
            Longitude
          </Typography>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default NavAppBar;
