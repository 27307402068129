import React from "react";

import CSSBaseline from "@material-ui/core/CssBaseline";

import BX24 from "./services/bitrix24";
import VisitPanel from "./container/VisitPanel";
import NavAppBar from "./display/NavAppBar";
import Panel from "./display/Panel";

import AppContext from "./context/AppContext";

const App = () => {
  const [appData, setAppData] = React.useState({});

  /* Uses Bitrix24 API to fetch fields present in multiple elements to be used in form VisitForm as select input options. */
  React.useEffect(() => {
    Promise.all([
      BX24.get("lists.field.get", {
        params: {
          IBLOCK_TYPE_ID: "lists",
          IBLOCK_ID: 32,
        },
      }),
      BX24.get("crm.lead.fields"),
    ])
      .then((responses) =>
        setAppData({
          listFields: responses[0].data.result,
          leadFields: responses[1].data.result,
        })
      )
      .catch((responses) => console.log(responses));
  }, []);

  return (
    <React.Fragment>
      <CSSBaseline />
      <NavAppBar />
      <Panel>
        <AppContext.Provider value={appData}>
          <VisitPanel />
        </AppContext.Provider>
      </Panel>
    </React.Fragment>
  );
};

export default App;
