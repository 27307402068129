import React from "react";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import BX24 from "../services/bitrix24";
import VisitForm from "../display/VisitForm";

const VisitPanel = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity()) {
      /* Checks if a Lead already exists with the same email in Bitrix24 . */
      let response = await BX24.get("crm.lead.list", {
        params: { filter: { EMAIL: form.email.value } },
      });
      let lead = response.data.result[0];

      /* Creates a Lead if it doesn't exist. */
      if (!lead) {
        response = await BX24.post("crm.lead.add", {
          fields: {
            TITLE: `${form.fullName.value} - Visita em plantão de vendas`,
            STATUS_ID: 14, // visita espontanea
            NAME: form.fullName.value.split(" ")[0],
            SOURCE_ID: 11,
            LAST_NAME: form.fullName.value.split(" ").slice(1).join(" "),
            PHONE: [{ VALUE: form.phone.value, VALUE_TYPE: "MOBILE" }],
            EMAIL: [{ VALUE: form.email.value, VALUE_TYPE: "HOME" }],
            UF_CRM_1598362761: form.venture.value,
            UF_CRM_1629385513950: form.realStateAgent.value,
            UF_CRM_1611931888: 6962,
          },
        });
        lead = { ID: response.data.result };
      }

      /* Creates a List element that registers the Lead visit to the sales point. */
      BX24.post("lists.element.add", {
        IBLOCK_TYPE_ID: "lists",
        IBLOCK_ID: 32,
        ELEMENT_ID: [form.fullName.value.replace(/\s/g, ""), lead.ID].join(""),
        FIELDS: {
          NAME: `${form.fullName.value} - Visita em plantão de vendas`,
          PROPERTY_112: form.realStateAgency.value,
          PROPERTY_116: form.medium.value,
          PROPERTY_126: form.realStateAgent.value,
          PROPERTY_110: form.salesPoint.value,
          PROPERTY_118: lead.ID,
          // PROPERTY_158: 292
        },
      }).catch((error) => console.log(error));
      setIsSubmitted(true);
    }
  };

  return isSubmitted ? (
    <Typography align="center" variant="h6">
      Recebemos o registro de visita! Registre outra clicando{" "}
      <Link href="/">aqui</Link>
    </Typography>
  ) : (
    <VisitForm handleSubmit={handleSubmit} />
  );
};

export default VisitPanel;
