import axios from "axios";
import qs from "qs";

const BX24 = axios.create({
  baseURL: "https://longitude.bitrix24.com/rest/1/fvpg0doqseu1gtl7/",
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "brackets", encode: false }),
});

export default BX24;
